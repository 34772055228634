import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form,  message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import AuthForm from '../../../../common/components/Forms/AuthForm/AuthForm';
import SMSFormRegister from '../../../../common/components/Forms/SMSFormRegister/SMSFormRegister';

import { clearUserServerResponse, signUp } from '../../../../redux/ducks/user';
import { checkSms, clearSmsServerResponse } from '../../../../redux/ducks/sms';

const RegistrationModal = ({ visible, onCancel }) => {

  const [form] = Form.useForm();
  const [isLoading, changeIsLoading] = useState(false);
  const [restoreValues, setRestoreValues] = useState({});
  const [sms, setSms] = useState({ is: false, КодСМС: '' });

  const serverResponse = useSelector((state) => state.user.serverResponse);
  const smsResponse = useSelector(state => state.sms.serverResponse);

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    onCancel();
    setTimeout(() => {
      form.resetFields();
      localStorage.removeItem('ph');
      setSms({ is: false, КодСМС: '' });
      setRestoreValues({});
    }, 200);
  }, [form, onCancel]);

  const onCreate = (values) => {
    changeIsLoading(true);
    if (!sms.is) {
      let vals = { ...values};
      setRestoreValues(vals);
      dispatch(signUp(vals));
    } else {
      dispatch(checkSms(values));
    };
  };

  const closeConfirm = () => {
    let values = form.getFieldsValue();
    if (JSON.stringify(values) === '{"register_type":"ПоНомеруДоговораЗаймаИМобильному"}') {
      onCancel();
    } else {
      Modal.confirm({
        // title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите прервать процесс регистрации? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          closeModal();
        },
      });
    }
  };

  const registerErrorConfirm = useCallback((err) => {
    // let msg =
    //   id === 1
    //     ? 'Не найдено ни одного клиента с указанным номером договора займа и мобильным.'
    //     : id === 2
    //     ? 'Не найдено ни одного клиента с указанным номером договора займа и Ф.И.О..'
    //     : id === 3
    //     ? 'Не найдено ни одного клиента с указанным номером договора сбережения и Ф.И.О..'
    //     : id === 4
    //     ? 'Не найдено ни одного клиента с указанным Ф.И.О. и мобильным.'
    //           : '';

    Modal.error({
      title: 'Ошибка!',
      content: err,
      centered: true,
    });
  }, []);

  const registerSuccessConfirm = useCallback(() => {
    Modal.success({
      title: 'Успех',
      content: 'Вы успешно зарегистрировались в системе',
      centered: true,
      onOk: () => {
        closeModal();
      },
    });
  }, [ closeModal ]);

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && visible && isLoading) {
      changeIsLoading(false);
      if (serverResponse.data.return.КодОшибки === '0') {
        if (!sms.is) {
          let phone = '';

          if(restoreValues.phone){
            phone = restoreValues.phone;
          } else {
            let c =
                serverResponse.data.return.СписокТелефонов &&
                serverResponse.data.return.СписокТелефонов.МобильныйТелефон
                ? serverResponse.data.return.СписокТелефонов.МобильныйТелефон
                : '';
              let b = c.replace(/[^\d;]/g, '').substr(-10, 10);
              // console.log(b)
              phone = b
          };

          if(phone){
            localStorage.setItem('ph', JSON.stringify(phone));
            setSms({ ...sms, is: true});
          };
        } else {
          registerSuccessConfirm();
        };
      } else {
        registerErrorConfirm(serverResponse.data.return.ОписаниеОшибки);
      };
      dispatch(clearUserServerResponse());
    };

    if (serverResponse && serverResponse.status === 'error' && visible && isLoading) {
      changeIsLoading(false);
      dispatch(clearUserServerResponse());
    };

    // if (serverResponse && serverResponse.status === 401 && visible && isLoading) {
    //   changeIsLoading(false);
    //   dispatch(clearUserServerResponse());
    //   notification.error({
    //     message: 'Ошибка',
    //     description: 'Проблемы с сервером',
    //   });
    // };

    if (smsResponse && smsResponse.status === 200 && isLoading) {
      if (smsResponse.data.result === 'success') {
        dispatch(signUp({ ...restoreValues}));
      } else if (smsResponse.data.result === 'error'){
        changeIsLoading(false);
        message.error('Код неверен');
      } else {
        changeIsLoading(false);
        message.error('Ошибка сервера или смс');
      }
      dispatch(clearSmsServerResponse());
    };



  }, [serverResponse, registerSuccessConfirm, registerErrorConfirm, dispatch, visible, smsResponse, isLoading, restoreValues, sms]);


  return (
    <Modal
      open={visible}
      title='Регистрация'
      okText='Зарегистрироваться'
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      centered
      confirmLoading={isLoading}
      className='change-password-modal'
    >
      {!sms.is ?
        <AuthForm form={form} isLoading={isLoading} visible={visible}/>
        :
        <SMSFormRegister
          form={form}
          isLoading={isLoading}
          visible={visible}
          type='registration'
        />
      }
    </Modal>
  );
};

export default memo(RegistrationModal);
